import { TableCell, TableRow, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

const BoxResultados = ({ trades }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [resultados, setResultados] = useState({});

  useEffect(() => {
    const totalOperaciones = trades.reduce(
      (acc, item) => acc + parseInt(item.TotalOperaciones),
      0
    );

    // console.log("trades totales: ", trades);
    // console.log("totalOperaciones: ", totalOperaciones);

    const totalGanadas = trades.reduce(
      (acc, item) => acc + parseInt(item.TotalGanadas),
      0
    );
    // console.log("totalGanadas: ", totalGanadas);

    const totalPerdidas = trades.reduce(
      (acc, item) => acc + parseInt(item.TotalPerdidas),
      0
    );
    // console.log("totalPerdidas: ", totalPerdidas);

    setResultados({
      totalOperaciones: totalOperaciones,
      totalGanadas: totalGanadas,
      totalPerdidas: totalPerdidas,
    });
  }, [trades]);

  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell
        component="th"
        scope="row"
        style={{
          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
          borderBottom: "0px",
        }}
      >
        TOTALES: {resultados.totalOperaciones}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{
          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
          borderBottom: "0px",
          color: "green",
        }}
      >
        GANADAS: {resultados.totalGanadas}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{
          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
          borderBottom: "0px",
          color: "red",
        }}
      >
        PERDIDAS: {resultados.totalPerdidas}
      </TableCell>
    </TableRow>
  );
};

export default BoxResultados;
