import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, IconButton, Typography } from "@mui/material";

const ComandoFlechas = ({ maxSlides, value, setValue, handleChangeDays }) => {
  return (
    <Box sx={{ width: "90%", display: "flex", justifyContent: "center" }}>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Alinea verticalmente al centro
          height: "100%", // Asegura que el contenedor ocupe toda la altura disponible
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center", // Centra verticalmente el slider dentro del Grid item
          }}
        >
          <IconButton color="primary" onClick={() => handleChangeDays("-")}>
            <ArrowBackIosNewIcon sx={{ fontSize: 50 }} />
          </IconButton>
          <Typography
            variant="h4"
            style={{
              paddingTop: "10px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
            gutterBottom
          >
            ÚLTIMOS {value} DÍAS
          </Typography>

          <IconButton color="primary" onClick={() => handleChangeDays("+")}>
            <ArrowForwardIosIcon sx={{ fontSize: 50 }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComandoFlechas;
