import { Box, Grid } from "@mui/material";
import { BarChart, ScatterChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import CustomBarChart from "./CustomBarCharts";

function App() {
  const series = [{ data: [-2, -9, 12, 11, 6, -4] }];

  const [colorX, setColorX] = useState("piecewise");
  const [colorY, setColorY] = useState("None");

  const [trades, setTrades] = useState([]);
  const [detalleTrades, setDetalleTrades] = useState([]);
  const [adicionales, setAdicionales] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const traerTradesPorDia = async () => {
    const API_ENDPOINT = `https://bot.bimtracker.com/backend/api_trades.php`;

    // console.log(codigoPedido);

    const datos = await fetch(API_ENDPOINT, {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: JSON.stringify({
        operacion: "GET_TRADES_POR_DIA",
        // operacion: "GET_TRADES_POR_DIA_2",
      }),
    });
    const respuesta = await datos.json();

    // console.log("respuesta: ", respuesta);

    if (respuesta.result.trades) {
      // console.log("trades: ", respuesta.result.trades);

      setTrades(respuesta.result.trades);
      setDetalleTrades(respuesta.result.detalleTrades);
      setAdicionales(respuesta.result.adicionales);
    } else {
      console.log("NO TRAE NADA EN traerTrades()");
      // setState([]);
    }
  };

  const init = async () => {
    let ret = await traerTradesPorDia();

    // setTrades(ret);
  };

  // init();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomBarChart
        trades={trades}
        detalleTrades={detalleTrades}
        adicionales={adicionales}
      />
    </Box>
  );
}

export default App;
