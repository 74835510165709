import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

// import customParseFormat from "dayjs/plugin/customParseFormat";
// dayjs.extend(customParseFormat);

function createData(fecha, profit) {
  return { fecha, profit };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

const Tabla = ({ trades }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  // const [sortedTrades, setSortedTrades] = useState(trades);

  useEffect(() => {
    trades.forEach((trade) => createData(trade.FechaCompleta, trade.Profit));
  }, []);

  // const formatFecha = (fecha) => {
  //   console.log("fecha: ", fecha);
  //   const date = new Date(fecha);

  //   // Usa dayjs para parsear la fecha con el formato adecuado
  //   // const date = dayjs(fecha, "DD/MM/YYYY HH:mm:ss");

  //   // // Verifica si la fecha es válida
  //   // if (!date.isValid()) {
  //   //   console.error("Fecha inválida:", fecha);
  //   //   return "";
  //   // }

  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");
  //   const seconds = String(date.getSeconds()).padStart(2, "0");
  //   console.log("dia: ", day);
  //   return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  // };

  // const formatFecha = (fecha) => {
  //   console.log("fecha: ", fecha);

  //   // Usa dayjs para parsear la fecha con el formato adecuado
  //   const date = dayjs(fecha, "DD/MM/YYYY HH:mm:ss", true);

  //   // Verifica si la fecha es válida
  //   if (!date.isValid()) {
  //     console.error("Fecha inválida:", fecha);
  //     return "";
  //   }

  //   // Obtén los componentes de la fecha usando los métodos de dayjs
  //   const day = date.format("DD");
  //   const month = date.format("MM");
  //   const year = date.format("YYYY");
  //   const hours = date.format("HH");
  //   const minutes = date.format("mm");
  //   const seconds = date.format("ss");

  //   console.log("día: ", day);
  //   return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  // };

  // console.log("t: ", trades);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" /*minWidth: 650*/ }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "60%" }}>Fecha</TableCell>
            <TableCell align="right" style={{ width: "40%" }}>
              Profit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trades.map((row, index) =>
            row.FechaCompleta ? (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
                  }}
                >
                  {row.FechaCompleta ? row.FechaCompleta : "-"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
                    color: row.Profit >= 0 ? "green" : "red",
                  }}
                >
                  {row.Profit >= 0 ? "+" + row.Profit : row.Profit}
                </TableCell>
              </TableRow>
            ) : (
              ""
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Tabla;
