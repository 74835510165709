import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);

// import customParseFormat from "dayjs/plugin/customParseFormat";
// dayjs.extend(customParseFormat);

function createData(fecha, profit, balance) {
  return { fecha, profit, balance };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

const TablaConDetalle = ({ trades, detalleTrades }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  // const [resultados, setResultados] = useState({});

  // const [sortedTrades, setSortedTrades] = useState(trades);
  // console.log("det: ", detalleTrades);

  useEffect(() => {
    trades.forEach((trade) =>
      createData(trade.FechaCompleta, trade.Profit, trade.Balance)
    );
  }, []);

  // useEffect(() => {
  //   console.log("tra: ", trades);
  // }, [trades]);

  // Row.propTypes = {
  //   row: PropTypes.shape({
  //     fecha: PropTypes.string.isRequired,
  //     profit: PropTypes.number.isRequired,
  //     detalle: PropTypes.arrayOf(
  //       PropTypes.shape({
  //         invertido: PropTypes.number.isRequired,
  //         profitTrade: PropTypes.string.isRequired,
  //       })
  //     ).isRequired,
  //   }).isRequired,
  // };

  // const formatFecha = (fecha) => {
  //   console.log("fecha: ", fecha);
  //   const date = new Date(fecha);

  //   // Usa dayjs para parsear la fecha con el formato adecuado
  //   // const date = dayjs(fecha, "DD/MM/YYYY HH:mm:ss");

  //   // // Verifica si la fecha es válida
  //   // if (!date.isValid()) {
  //   //   console.error("Fecha inválida:", fecha);
  //   //   return "";
  //   // }

  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");
  //   const seconds = String(date.getSeconds()).padStart(2, "0");
  //   console.log("dia: ", day);
  //   return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  // };

  // const formatFecha = (fecha) => {
  //   console.log("fecha: ", fecha);

  //   // Usa dayjs para parsear la fecha con el formato adecuado
  //   const date = dayjs(fecha, "DD/MM/YYYY HH:mm:ss", true);

  //   // Verifica si la fecha es válida
  //   if (!date.isValid()) {
  //     console.error("Fecha inválida:", fecha);
  //     return "";
  //   }

  //   // Obtén los componentes de la fecha usando los métodos de dayjs
  //   const day = date.format("DD");
  //   const month = date.format("MM");
  //   const year = date.format("YYYY");
  //   const hours = date.format("HH");
  //   const minutes = date.format("mm");
  //   const seconds = date.format("ss");

  //   console.log("día: ", day);
  //   return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  // };

  // console.log("t: ", trades);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={{ width: "50%" }}>Fecha</TableCell>
            <TableCell align="right" style={{ width: "25%" }}>
              Balance
            </TableCell>
            <TableCell align="right" style={{ width: "25%" }}>
              Profit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trades.map((trade) => (
            // console.log("deta:  ", detalleTrades)
            <Row
              trade={trade}
              detalleTrades={detalleTrades}
              balanceIngresosEgresos={trade.SumaIngresos - trade.SumaEgresos}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaConDetalle;

const Row = ({ trade, detalleTrades, balanceIngresosEgresos }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  // const [sumaIngresosRetiros, setSumaIngresosRetiros] = useState(0);

  const [detalleFiltrados, setDetalleFiltrados] = useState([]);
  const [open, setOpen] = React.useState(false);

  const formatFecha = (fecha) => {
    // console.log("fecha 1: ", fecha);

    // console.log("fecha 2: ", fecha.substr(0, 10));
    return fecha ? fecha.substr(0, 10) : null;

    // // // Usa dayjs para parsear la fecha con el formato adecuado
    // const date = dayjs(fecha, "DD/MM/YYYY", true);
    // // // Verifica si la fecha es válida
    // // if (!date.isValid()) {
    // //   console.error("Fecha inválida:", fecha);
    // //   return "";
    // // }
    // // // Obtén los componentes de la fecha usando los métodos de dayjs
    // const day = date.format("DD");
    // const month = date.format("MM");
    // const year = date.format("YYYY");
    // // const hours = date.format("HH");
    // // const minutes = date.format("mm");
    // // const seconds = date.format("ss");
    // // console.log("día: ", day);
    // return `${day}/${month}/${year}`;
  };

  const formatFecha2 = (fecha) => {
    // console.log("fecha 3: ", fecha);

    // // Usa dayjs para parsear la fecha con el formato adecuado
    const date = dayjs(fecha, "DD/MM/YYYY", true);
    // // Verifica si la fecha es válida
    // if (!date.isValid()) {
    //   console.error("Fecha inválida:", fecha);
    //   return "";
    // }
    // // Obtén los componentes de la fecha usando los métodos de dayjs
    const day = date.format("DD");
    const month = date.format("MM");
    const year = date.format("YYYY");
    // const hours = date.format("HH");
    // const minutes = date.format("mm");
    // const seconds = date.format("ss");
    // console.log("día: ", day);
    // console.log("fecha 4: ", `${day}/${month}/${year}`);
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    // console.log("detalleTrades: ", detalleTrades);
    // console.log("trade: ", trade);

    // detalleTrades.map((item) =>
    //   console.log("FechaCompleta: ", formatFecha(trade.FechaCompleta))
    // );

    // let totalOperaciones = detalleTrades.length
    // let operacionesGanadas = detalleTrades

    let filtroDetalles = detalleTrades.filter(
      (item) =>
        trade.FechaCompleta &&
        formatFecha2(item.Fecha) === formatFecha(trade.FechaCompleta)
    );

    // console.log("filtroDetalles: ", filtroDetalles);

    // console.log("detalleFiltrados: ", detalleFiltrados);

    // console.log("Balance: ", trade);
    // console.log("balanceIngresosEgresos: ", balanceIngresosEgresos);

    // setSumaIngresosRetiros(0);
    setDetalleFiltrados(filtroDetalles);
  }, []);

  // useEffect(() => {
  //   calcularSumaRetiros();
  // }, [detalleFiltrados]);

  // const calcularSumaRetiros = () => {
  //   let suma = 0;

  //   detalleFiltrados.forEach(
  //     (item) =>
  //       (suma =
  //         item.Resultado === "INGRESO"
  //           ? parseFloat(suma + item.Invertido)
  //           : item.Resultado === "EGRESO"
  //           ? parseFloat(suma - item.Invertido)
  //           : parseFloat(suma + 0))
  //   );

  //   console.log("suma: ", suma);

  //   setSumaIngresosRetiros(suma);
  // };

  const abrirLista = (fecha) => {
    setOpen(!open);

    let filtroDetalles = detalleTrades.filter(
      (item) =>
        trade.FechaCompleta &&
        formatFecha2(item.Fecha) === formatFecha(trade.FechaCompleta)
    );

    // console.log("filtroDetalles: ", filtroDetalles);

    // console.log("detalleFiltrados: ", detalleFiltrados);
    setDetalleFiltrados(filtroDetalles);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => abrirLista(trade.Fecha)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          style={{
            fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
          }}
        >
          {trade.Fecha ? trade.Fecha : "-"}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
            color: trade.Balance >= 0 ? "green" : "red",
          }}
        >
          {trade.Balance >= 0 ? "+" + trade.Balance : trade.Balance}
        </TableCell>
        <TableCell
          align="right"
          style={{
            fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
            color: trade.Profit >= 0 ? "green" : "red",
          }}
        >
          {trade.Profit >= 0 ? "+" + trade.Profit : trade.Profit}
          {balanceIngresosEgresos !== 0 ? (
            <>
              &nbsp;
              <span
                style={{
                  fontSize: isMobile ? "12px" : "18px", // Disminuir el tamaño de la letra en móviles
                  color: balanceIngresosEgresos > 0 ? "#0d47a1" : "#b71c1c",
                }}
              >
                (
                {balanceIngresosEgresos > 0
                  ? "+" + balanceIngresosEgresos
                  : balanceIngresosEgresos}
                )
              </span>
            </>
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Detalle
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Hora</TableCell>
                    <TableCell>Invertido</TableCell>
                    <TableCell align="right">Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detalleFiltrados.map((det, index) => (
                    <TableRow
                      key={det.Fecha}
                      sx={
                        det.Resultado === "INGRESO"
                          ? { backgroundColor: "#BBDEFB" }
                          : det.Resultado === "EGRESO"
                          ? { backgroundColor: "#FFCDD2" }
                          : ""
                      }
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
                          color:
                            det.Resultado === "INGRESO"
                              ? "#0d47a1"
                              : det.Resultado === "EGRESO"
                              ? "#b71c1c"
                              : "",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
                          color:
                            det.Resultado === "INGRESO"
                              ? "#0d47a1"
                              : det.Resultado === "EGRESO"
                              ? "#b71c1c"
                              : "",
                        }}
                      >
                        {det.Fecha ? det.Fecha.substr(11) : "-"}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
                          color:
                            det.Resultado === "INGRESO"
                              ? "#0d47a1"
                              : det.Resultado === "EGRESO"
                              ? "#b71c1c"
                              : "",
                        }}
                      >
                        {det.Invertido
                          ? parseFloat(det.Invertido, 2) + " USDT"
                          : "-"}
                      </TableCell>

                      <TableCell
                        align="right"
                        style={{
                          fontSize: isMobile ? "14px" : "20px", // Disminuir el tamaño de la letra en móviles
                          color:
                            det.Resultado === "INGRESO"
                              ? "#0d47a1"
                              : det.Resultado === "EGRESO"
                              ? "#b71c1c"
                              : det.ProfitTrade >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {det.ProfitTrade >= 0
                          ? "+" + det.ProfitTrade
                          : det.ProfitTrade}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
