import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import Tabla from "./Tabla";
import SliderFechas from "./SliderFechas";
import TablaConDetalle from "./TablaConDetalle";
import BoxResultados from "./BoxResultados";
import ComandoFlechas from "./ComandoFlechas";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
dayjs.extend(isSameOrBefore);

const formatNumber = (number) => {
  return new Intl.NumberFormat("de-DE").format(number);
};

const generateDateRange = (startDate, endDate, originalTrades) => {
  let dateArray = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isSameOrBefore(dayjs(endDate))) {
    // Encuentra la transacción original que coincide con la fecha actual
    const originalTrade = originalTrades.find(
      (trade) =>
        dayjs(trade.Fecha, "DD/MM/YYYY HH:mm:ss").format("DD/MM") ===
        currentDate.format("DD/MM")
    );

    // Usa la hora, minutos y segundos de la transacción original si existe, de lo contrario, usa 00:00:00
    const horaCompleta = originalTrade
      ? originalTrade.Fecha
      : currentDate.format("DD/MM/YYYY 00:00:00");

    dateArray.push({
      Fecha: currentDate.format("DD/MM"),
      FechaCompleta: horaCompleta,
    });

    currentDate = currentDate.add(1, "day");
  }
  return dateArray;
};

const CustomBarChart = ({ trades, detalleTrades, adicionales }) => {
  // const [totalInvertido, setTotalInvertido] = useState(90);
  const [filterDays, setFilterDays] = useState(1);
  // const [orderedTrades, setOrderedTrades] = useState([]);

  // console.log("d: ", detalleTrades);
  const [value, setValue] = useState(1);

  // Filtrar y obtener la fecha más reciente en los datos
  const validTrades = trades.filter(
    (trade) =>
      trade &&
      trade.Fecha &&
      // trade.Profit &&
      // trade.PorcentajeProfit &&
      trade.Balance
  );

  const validMovimientos = adicionales.filter(
    (adicional) =>
      adicional && adicional.Fecha && adicional.Invertido && adicional.Resultado
  );

  const maxDate = validTrades.reduce((latest, trade) => {
    const tradeDate = dayjs(trade.Fecha, "DD/MM");
    return tradeDate.isAfter(latest) ? tradeDate : latest;
  }, dayjs()); // Inicializar con la fecha actual

  // Crear el rango de fechas según el filtro seleccionado
  const startDate = maxDate.subtract(filterDays - 1, "day");
  const dateRange = generateDateRange(startDate, maxDate, validTrades);

  // Filtrar los datos según el rango de fechas generado
  const filteredTrades = dateRange.map(({ Fecha, FechaCompleta }) => {
    const trade = validTrades.find(
      (trade) =>
        dayjs(trade.Fecha, "DD/MM/YYYY HH:mm:ss").format("DD/MM") === Fecha
    );

    // console.log("trade: ", trade);
    return {
      Fecha,
      FechaCompleta: trade
        ? dayjs(trade.Fecha, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )
        : null,
      Profit: trade ? parseFloat(trade.Profit) : 0,
      PorcentajeProfit: trade ? parseFloat(trade.PorcentajeProfit) : 0,
      Balance: trade ? parseFloat(trade.Balance) : 0,
      TotalOperaciones: trade ? trade.TotalOperaciones : 0,
      TotalGanadas: trade ? trade.TotalGanadas : 0,
      TotalPerdidas: trade ? trade.TotalPerdidas : 0,
      SumaIngresos: trade
        ? trade.SumaIngresos !== null
          ? parseFloat(trade.SumaIngresos)
          : 0
        : 0,
      SumaEgresos: trade
        ? trade.SumaEgresos !== null
          ? parseFloat(trade.SumaEgresos)
          : 0
        : 0,
      Invertido: trade ? trade.Invertido : 0,
      InvertidoEnSimultaneo: trade ? trade.InvertidoEnSimultaneo : 0,
    };
  });

  console.log("filtered: ", filteredTrades);

  const totalIngresos = filteredTrades.reduce(
    (acc, item) => parseFloat(acc + item.SumaIngresos),
    0
  );

  const totalEgresos = filteredTrades.reduce(
    (acc, item) => parseFloat(acc + item.SumaEgresos),
    0
  );

  // const fitroSoloTrades = filteredTrades.filter((trade)=>trade.Resultado !== "INGRESO" && trade.Resultado !== "EGRESO")

  // Calcular el profit total
  // const totalProfitSegunBalance = parseFloat(
  //   filteredTrades[filteredTrades.length - 1].Balance +
  //     filteredTrades[filteredTrades.length - 1].Profit -
  //     filteredTrades[0].Balance
  //   // -
  //   // // +
  //   // // filteredTrades[0].SumaIngresos -
  //   // // filteredTrades[0].SumaEgresos -
  //   // totalIngresos +
  //   // totalEgresos
  // );

  const totalProfitSegunBalance =
    filteredTrades.length <= 1
      ? parseFloat(filteredTrades[0].Profit)
      : parseFloat(
          filteredTrades[filteredTrades.length - 1].Balance +
            filteredTrades[filteredTrades.length - 1].Profit -
            filteredTrades[0].Balance -
            // +
            // filteredTrades[0].SumaIngresos -
            // filteredTrades[0].SumaEgresos
            totalIngresos +
            totalEgresos
        );

  // const porcentajeProfitSegunBalance = parseFloat(
  //   ((filteredTrades[filteredTrades.length - 1].Balance +
  //     filteredTrades[filteredTrades.length - 1].Profit) *
  //     // -
  //     // //  +
  //     // //  filteredTrades[0].SumaIngresos -
  //     // //  filteredTrades[0].SumaEgresos -
  //     // totalIngresos +
  //     // totalEgresos
  //     100) /
  //     filteredTrades[0].Balance -
  //     100
  // );

  const porcentajeProfitSegunBalance =
    filteredTrades.length <= 1
      ? parseFloat(
          (filteredTrades[0].Profit * 100) /
            (filteredTrades[0].Profit > 0
              ? filteredTrades[0].Balance - 100
              : filteredTrades[0].Balance)
        )
      : parseFloat(
          ((filteredTrades[filteredTrades.length - 1].Balance +
            filteredTrades[filteredTrades.length - 1].Profit -
            // +
            // filteredTrades[0].SumaIngresos -
            // filteredTrades[0].SumaEgresos
            totalIngresos +
            totalEgresos) *
            100) /
            filteredTrades[0].Balance -
            100
        );

  // const porcentajeProfitSegunBalance = parseFloat(
  //   ((filteredTrades[filteredTrades.length - 1].Balance +
  //     filteredTrades[filteredTrades.length - 1].Profit) *
  //     // -
  //     // totalIngresos +
  //     // totalEgresos
  //     100) /
  //     filteredTrades[0].Balance -
  //     100
  // );

  // console.log("balance-1: ", filteredTrades[filteredTrades.length - 1].Balance);
  // console.log("profit-1: ", filteredTrades[filteredTrades.length - 1].Profit);
  // console.log("totalIngresos: ", totalIngresos);
  // console.log("totalEgresos: ", totalEgresos);
  // console.log("balance 0: ", filteredTrades[0].Balance);
  // console.log("porcentajeProfitSegunBalance: ", porcentajeProfitSegunBalance);

  const totalProfitSegunGanancias = filteredTrades.reduce(
    (acc, item) => acc + item.Profit,
    0
  );

  // const sumaInvertido = filteredTrades.reduce(
  //   (acc, item) =>
  //     acc + item.Invertido !== null ? parseFloat(item.Invertido) : 0, // item.PorcentajeProfit,
  //   0
  // );

  const sumaInvertidoEnSimultaneo = filteredTrades.reduce(
    (acc, item) =>
      acc +
      (item.InvertidoEnSimultaneo !== null
        ? parseFloat(item.InvertidoEnSimultaneo)
        : 0),
    0
  );

  console.log("sumaInvertidoEnSimultaneo: ", sumaInvertidoEnSimultaneo);

  const sumaTotalOperaciones = filteredTrades.reduce(
    (acc, item) =>
      acc +
      (item.TotalOperaciones !== null ? parseFloat(item.TotalOperaciones) : 0), // item.PorcentajeProfit,
    0
  );

  console.log("sumaTotalOperaciones: ", sumaTotalOperaciones);

  const promedioInversion = parseFloat(
    sumaInvertidoEnSimultaneo / sumaTotalOperaciones
  );

  console.log("promedio: ", promedioInversion);

  const porcentajeProfitSegunGanancias =
    (totalProfitSegunGanancias * 100) / promedioInversion;

  console.log(
    "porcentajeProfitSegunGanancias: ",
    porcentajeProfitSegunGanancias
  );

  const [balanceSegunGanancias, setBalanceSegunGanancias] = useState(true);
  const [profit, setProfit] = useState({
    totalProfit: totalProfitSegunGanancias,
    porcentajeProfit: porcentajeProfitSegunGanancias,
  });

  // console.log("porcentaje: ", isNaN(porcentajeProfitSegunGanancias));

  // useEffect(() => {
  // let orderedTrades = filteredTrades.sort((a, b) =>
  //   dayjs(b.FechaCompleta, "DD/MM/YYYY HH:mm:ss").diff(
  //     dayjs(a.FechaCompleta, "DD/MM/YYYY HH:mm:ss")
  //   )
  // );

  //   setOrderedTrades(tradesOrdenados);
  // }, []);

  // console.log("filtered: ", filteredTrades);

  // let tradesOrdenados = filteredTrades;

  // // Ordenar el array por FechaCompleta de la más reciente a la más antigua
  // const sortedTrades = tradesOrdenados.sort((a, b) => {
  //   const dateA = dayjs(a.FechaCompleta, "DD/MM/YYYY HH:mm:ss");
  //   const dateB = dayjs(b.FechaCompleta, "DD/MM/YYYY HH:mm:ss");

  //   // Si una fecha es null, la consideramos como la más antigua
  //   if (!a.FechaCompleta) return 1;
  //   if (!b.FechaCompleta) return -1;

  //   return dateB - dateA;
  // });

  // console.log("sortedTrades: ", sortedTrades);

  // console.log("orderedTrades: ", orderedTrades);

  const switchBalance = () => {
    if (balanceSegunGanancias) {
      setProfit({
        totalProfit: totalProfitSegunGanancias,
        porcentajeProfit: isNaN(porcentajeProfitSegunGanancias)
          ? 0
          : porcentajeProfitSegunGanancias,
      });
    } else {
      setProfit({
        totalProfit: totalProfitSegunBalance,
        porcentajeProfit: porcentajeProfitSegunBalance,
      });
    }
  };

  useEffect(() => {
    switchBalance();
  }, [balanceSegunGanancias, filteredTrades]);

  // console.log("totalIngresos: ", totalIngresos);
  // console.log("totalEgresos: ", totalEgresos);

  const [buttonText, setButtonText] = useState("Último día");

  const handleButtonClick = (days, text) => {
    setFilterDays(days);
    setButtonText(text);
  };

  // Estado para almacenar el ancho de la ventana
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.8);

  useEffect(() => {
    // Actualiza el ancho del gráfico cuando la ventana cambia de tamaño
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.8);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleButtonClick(value, `Últimos ${value} días`);
  }, [value]);

  const handleChange = (event, newValue) => {
    // console.log("newValue: ", newValue);

    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  const handleChangeDays = (signo) => {
    // console.log("value: ", value);

    let val = value;

    if (signo === "+") {
      setValue(parseInt(val + 1));
    } else if (signo === "-") {
      setValue(parseInt(val - 1));
    }
  };

  return (
    <div>
      <Grid
        container
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        <Grid item xs={12}></Grid>
      </Grid>

      {/* <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>

        <Grid item xs={12} sm={10} md={8} lg={6} style={{ padding: "5px" }}>
          <ComandoFlechas
            maxSlides={trades.length}
            value={value}
            setValue={setValue}
            handleChangeDays={handleChangeDays}
          />
        </Grid>
      </Grid> */}

      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            marginTop: "40px",
            border: "1px solid #000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {buttonText && (
            // <Typography
            //   variant="h6"
            //   style={{ textAlign: "center", width: "100%" }}
            //   gutterBottom
            // >
            //   {buttonText.toUpperCase()}
            // </Typography>
            <ComandoFlechas
              maxSlides={trades.length}
              value={value}
              setValue={setValue}
              handleChangeDays={handleChangeDays}
            />
          )}
        </Grid>
      </Grid>

      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            marginTop: "40px",
            border: "1px solid #000000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={8}
            lg={6}
            direction="column"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              // marginTop: "40px",
              // border: "1px solid #000000",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              style={{ fontSize: "40px", width: "100%", textAlign: "center" }}
              gutterBottom
            >
              $ {formatNumber(profit.totalProfit.toFixed(3))}{" "}
              <span style={{ fontSize: "0.5em" }}>
                (
                {
                  // formatNumber(((totalProfit * 100) / totalInvertido).toFixed(2))
                  formatNumber(profit.porcentajeProfit.toFixed(2))
                }
                %)
              </span>
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                width: "100%",
                textAlign: "center",
                marginRight: "10px",
              }}
              gutterBottom
            >
              {balanceSegunGanancias
                ? "PROFIT SEGÚN INVERSIÓN"
                : "INCREMENTO DE BALANCE"}
            </Typography>
          </Grid>

          <IconButton
            color="primary"
            onClick={() => setBalanceSegunGanancias(!balanceSegunGanancias)}
          >
            <CurrencyExchangeIcon sx={{ fontSize: 40, marginRight: "5px" }} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ display: "flex", justifyContent: "center", maxWidth: "100%" }}
      >
        <Grid item xs={12}></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          style={{
            paddingTop: "35px",
            marginTop: "40px",
            border: "1px solid #000000",
            maxWidth: "100%",
          }}
        >
          <BarChart
            width={chartWidth} // Se usa el valor dinámico calculado
            height={300}
            data={filteredTrades}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Fecha" />
            <YAxis tickCount={10} interval="preserveEnd" />
            <Tooltip />
            <Legend />
            <Bar dataKey="Profit" fill="#8884d8">
              {filteredTrades.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.Profit >= 0 ? "green" : "red"}
                />
              ))}
            </Bar>
          </BarChart>
        </Grid>
      </Grid>

      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            marginTop: "40px",
            border: "1px solid #000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* <Tabla trades={filteredTrades} /> */}
          <BoxResultados trades={filteredTrades} />
        </Grid>
      </Grid>

      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid item xs={12}></Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={6}
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            marginTop: "40px",
            border: "1px solid #000000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {/* <Tabla trades={filteredTrades} /> */}
          <TablaConDetalle
            trades={filteredTrades}
            detalleTrades={detalleTrades}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomBarChart;
